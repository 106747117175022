import Vuex from 'vuex'

export function generateVuexStoreObject (runtimeConfig) {
  return new Vuex.Store({
    state: {
      runtimeConfig: runtimeConfig
    },
    mutations: {},
    actions: {},
    getters: {}
  })
}
