<script>
// @ is an alias to /src
import FooterSapa from '@/components/FooterSapa.vue'
import Header from '@/components/Header.vue'
import Main from '@/components/Main.vue'

export default {
  name: 'AppSapa',
  components: {
    FooterSapa,
    Header,
    Main
  },
  computed: {
    isAdminView () {
      return this.$route ? this.$route.meta.viewType === 'admin' : false
    }
  }
}
</script>
<template>
  <div id="app">
    <b-container fluid class="sticky-top main-header">
      <b-row>
        <b-col>
          <Header />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="main">
          <Main />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col class="footer">
          <FooterSapa />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
