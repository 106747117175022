<template>
  <header>
    <b-row class="header">
      <b-col class="d-none d-md-block" md="3">
        <div class="header-left">
          <a :href="url.homePage" target="_blank">
            <img v-b-tooltip.hover="showTooltip('tooltip-ka-homepage')"
                 class="header-left"
                 src="@/assets/images/ka-logo-center.png" />
          </a>
        </div>
      </b-col>
      <b-col md="9" lg="7">
      </b-col>
      <b-col lg="2" align-self="center">
        <b-navbar-nav class="ml-auto">
          <b-nav-item>
            <font-awesome-icon class="fa-1x header-lang"
                               :icon="['fas', 'globe']" />
              <a @click="changeLang('fi')" class="header-lang">
                <translate translate-context="Header">fi</translate>
              </a>
              |
              <a @click="changeLang('sv')" class="header-lang">
                <translate translate-context="Header">sv</translate>
              </a>
          </b-nav-item>
        </b-navbar-nav>
      </b-col>
    </b-row>
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    showTooltip (target) {
      return this.$pgettext('tooltips', target)
    },
    changeLang (lang) {
      this.$language.current = lang
    }
  },
  computed: {
    isUploader () {
      return this.$store.state.user.role === 'uploader'
    },
    isAdmin () {
      return this.$store.state.user.role === 'admin'
    },
    isAcceptedUser () {
      // Accepted users are those with valid active role
      return ['admin', 'content_maintainer', 'uploader'].includes(
        this.$store.state.user.role)
    },
    url () {
      if (this.$language.current === 'sv') {
        return {
          homePage: 'https://kansallisarkisto.fi/sv/framsida'
        }
      }
      return {
        homePage: 'https://kansallisarkisto.fi'
      }
    }
  }
}
</script>
