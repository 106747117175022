<template>
  <footer>

    <!-- Verkkosivuston tiedot -->
    <b-modal id="siteinfo-modal" size="xl">
      <template v-slot:modal-title>
        <translate translate-context="Site info modal">
          translation_site_info_modal_title_text
        </translate>
      </template>
      <p>
        <translate translate-context="Site info modal">
          translation_site_info_modal_body_text_1
        </translate>

        <a href="https://validointi-sapa.csc.fi/"
           target="_blank">https://validointi-sapa.csc.fi/</a>

       <translate translate-context="Site info modal">
          translation_site_info_modal_body_text_2
        </translate>
      </p>

      <p>
        <translate translate-context="Site info modal">
          translation_site_info_modal_body_text_3
        </translate>
      </p>

      <p>
        <translate translate-context="Site info modal">
          translation_site_info_modal_body_text_4
        </translate>

        <a href="mailto:digitaalisetsiirrot@kansallisarkisto.fi">
          digitaalisetsiirrot@kansallisarkisto.fi</a>
      </p>
      <template #modal-footer="{ hide }">
        <b-button class="button" @click="hide()">
          <translate translate-context="Modal window">Close</translate>
        </b-button>
      </template>
    </b-modal>

    <!-- Saavutettavuus -->
    <b-modal id="accessability-modal" size="xl">
      <template v-slot:modal-title>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_title_text
        </translate>
      </template>

      <!-- Saavutettavuus: Saavutettavuusseloste -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_1
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_1
        </translate>
      </p>

      <!-- Saavutettavuus: Verkkopalvelun saavutettavuuden tila -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_2
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_2
        </translate>
      </p>

      <!-- Saavutettavuus: Ei-saavutettava sisältö -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_3
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_3
        </translate>
      </p>

     <!-- Saavutettavuus: Kansallisarkiston muut verkkopalvelut -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_4
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_4
        </translate>
        <a href="mailto:kirjaamo@kansallisarkisto.fi">
          kirjaamo@kansallisarkisto.fi</a>.
      </p>

      <!-- Saavutettavuus: Saavutettavuuspalaute -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_5
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_5
        </translate>
        <a href="mailto:kirjaamo@kansallisarkisto.fi">
          kirjaamo@kansallisarkisto.fi</a>.
      </p>
      <p>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_6
        </translate>
      </p>
      <p>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_7
        </translate>
      </p>

      <!-- Saavutettavuus: Valvontaviranomaisen yhteystiedot -->
      <p><b>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_subtitle_text_6
        </translate>
      </b><br />
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_8
        </translate>
      </p>
      <p>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_9
        </translate>
        <a :href="url.accessability"
           target="_blank">{{ url.accessability }}</a>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_10
        </translate>
      </p>
      <p>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_11
        </translate>
      </p>
      <p>
        <translate translate-context="Accessability modal">
          translation_accessability_modal_body_text_12
        </translate>
      </p>
      <template #modal-footer="{ hide }">
        <b-button class="button" @click="hide()">
          <translate translate-context="Modal window">Close</translate>
        </b-button>
      </template>
    </b-modal>

    <!-- Evästeet  -->
    <b-modal id="cookieinfo-modal" size="xl">
      <template v-slot:modal-title>
        <translate translate-context="Cookie info modal">
          translation_cookie_info_modal_title_text
        </translate>
      </template>

      <!-- Evästeet: Yleisesti -->
      <p><b>
        <translate translate-context="Cookie info modal">
          translation_cookie_info_subtitle_text_1
        </translate>
      </b><br />
        <translate translate-context="Cookie info modal">
          translation_cookie_info_modal_body_text_1
        </translate>

        <a href="https://validointi-sapa.csc.fi/"
           target="_blank">https://validointi-sapa.csc.fi/</a>

        <translate translate-context="Cookie info modal">
          translation_cookie_info_modal_body_text_2
        </translate>
      </p>

      <!-- Evästeet: Muut evästeet -->
      <p><b>
        <translate translate-context="Cookie info modal">
          translation_cookie_info_subtitle_text_2
        </translate>
      </b><br />
        <a href="https://validointi-sapa.csc.fi/"
           target="_blank">https://validointi-sapa.csc.fi/</a>

        <translate translate-context="Cookie info modal">
          translation_cookie_info_modal_body_text_3
        </translate>
      </p>
      <template #modal-footer="{ hide }">
        <b-button class="button" @click="hide()">
          <translate translate-context="Modal window">Close</translate>
        </b-button>
      </template>
    </b-modal>

    <b-row>
      <b-col cols="7" sm="5" md="4">
        <div class="footer-links">
          <b-row>
            <img class="footer-logo" src="@/assets/images/ka-logo.png">
          </b-row>

          <a :href="url.homePage"
             target="_blank">{{ text.homePage }}</a>
          <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"
                             class="footer-icons"/><br />

          <a :href="url.digitalArchivingService"
             target="_blank">{{ text.digitalArchivingService }}</a>
          <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"
                             class="footer-icons"/><br />

          <a :href="url.contactInformation"
             target="_blank">{{ text.contactInformation }}</a>
          <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"
                             class="footer-icons"/><br />

          <a :href="url.dataProtection"
             target="_blank">{{ text.dataProtection }}</a>
          <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"
                             class="footer-icons"/><br />

          <button v-b-modal="'siteinfo-modal'" class="footer-button">
            <translate translate-context="Site info modal">
              translation_site_info_modal_title_text
            </translate>
          </button>
          <br />

          <button v-b-modal="'accessability-modal'" class="footer-button">
            <translate translate-context="Accessability modal">
              translation_accessability_modal_subtitle_text_1
            </translate>
          </button>
          <br />

          <button v-b-modal="'cookieinfo-modal'" class="footer-button">
            <translate translate-context="Cookie info modal">
              translation_cookie_info_modal_title_text
            </translate>
          </button>
          <br />

          <a href="mailto:digitaalisetsiirrot@kansallisarkisto.fi">
             <u>digitaalisetsiirrot@kansallisarkisto.fi</u></a>
          <font-awesome-icon :icon="['far', 'envelope']"
                             class="footer-icons"/><br />

          <b-row class="footer-brand-icons-row justify-content-between">
            <a href="https://www.facebook.com/Kansallisarkisto" target="_blank">
              <font-awesome-icon :icon="['fab', 'square-facebook']" /></a>

            <a href="https://twitter.com/Kansallisarkist"
               target="_blank">
              <font-awesome-icon :icon="['fab', 'x-twitter']" /></a>

            <a href="https://www.instagram.com/kansallisarkisto/"
               target="_blank">
              <font-awesome-icon :icon="['fab', 'instagram']" /></a>

            <a href="https://www.youtube.com/Kansallisarkisto_Riksarkivet"
               target="_blank">
              <font-awesome-icon :icon="['fab', 'youtube']" /></a>

            <a href="https://fi.linkedin.com/company/kansallisarkisto"
               target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" /></a>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: 'FooterSapa',
  computed: {
    text () {
      return {
        contactInformation: this.$pgettext('Footer', 'Contact information'),
        dataProtection: this.$pgettext('Footer', 'Data protection'),
        digitalArchivingService: this.$pgettext('Footer', 'Digital archiving service'),
        homePage: this.$pgettext('Footer', 'Home page')
      }
    },
    url () {
      if (this.$language.current === 'sv') {
        return {
          accessability: 'https://www.tillganglighetskrav.fi',
          contactInformation: 'https://kansallisarkisto.fi/sv/kontaktuppgifter',
          dataProtection: 'https://kansallisarkisto.fi/sv/dataskydd',
          digitalArchivingService: 'https://kansallisarkisto.fi/sv/digitalarkivering',
          homePage: 'https://kansallisarkisto.fi/sv/framsida'
        }
      }
      return {
        accessability: 'https://www.saavutettavuusvaatimukset.fi',
        contactInformation: 'https://kansallisarkisto.fi/yhteystiedot',
        dataProtection: 'https://kansallisarkisto.fi/tietosuoja',
        digitalArchivingService: 'https://kansallisarkisto.fi/sapa',
        homePage: 'https://kansallisarkisto.fi/etusivu'
      }
    }
  }

}
</script>
