<template>
  <main>
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'Main',
  computed: {
    pageTitle: function () {
      return this.$pgettext('Title', this.$route.meta.fullTitle)
    },
    showLogo: function () {
      return this.$route.name === 'Home'
    }
  }
}
</script>
