import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Validate',
    component: () =>
      import(/* webpackChunkName: "validate" */ '@/views/ValidateSapa.vue'),
    meta: {
      viewType: 'admin',
      shortTitle: 'Validate',
      fullTitle: 'Validate'
    },
    children: [
      {
        path: ':id',
        name: 'Validate in progress',
        component: () =>
          import(/* webpackChunkName: "validate" */ '@/views/ValidateSapa.vue')
      }
    ]
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
    meta: {
      viewType: 'normal'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
