import Vue from 'vue'
import Vuex from 'vuex'
import GetTextPlugin from 'vue-gettext'
import translations from '@/translations/translations.json'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/ka-tyylit.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope as farEnvelope,
  faSquare as farSquare,
  faSquareCheck as farSquareCheck
} from '@fortawesome/free-regular-svg-icons'
import {
  faArrowUpRightFromSquare,
  faGlobe,
  faHourglass,
  faSquare as fasSquare,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'
import AppSapa from '@/AppSapa.vue'
import router from '@/router/sapa.js'
import { generateVuexStoreObject } from '@/store/index.js'

// axios is needed to initialize runtime configuration file.
import axios from 'axios'

const fetchConfig = async () => {
  const resp = await axios.get('/config.json')
  let config = resp.data
  if (process.env.NODE_ENV !== 'production') {
    try {
      // Use development config to override configuration parameters
      const devResp = await axios.get('/config.dev.json')
      config = Object.assign(config, devResp.data)
    } catch (e) {
      console.log('Development config not loaded')
    }
  }

  const baseHost = `${config.DOMAIN}/api/${config.VERSION}`
  return {
    api: {
      endpoints: {
        tusFileUploads: `${baseHost}/${config.TUS_UPLOADS_ENDPOINT}`,
        validationStatus: `${baseHost}/${config.FILE_VALIDATION_ENDPOINT}`
      }
    }
  }
}

fetchConfig()
  .then(config => {
    Vue.use(Vuex)
    const store = generateVuexStoreObject(config)
    library.add(
      faArrowUpRightFromSquare,
      faGlobe,
      faHourglass,
      faInstagram,
      faLinkedin,
      farEnvelope,
      farSquare,
      farSquareCheck,
      faSquareFacebook,
      fasSquare,
      faThumbsDown,
      faThumbsUp,
      faXTwitter,
      faYoutube
    )
    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)
    Vue.component('font-awesome-icon', FontAwesomeIcon)
    Vue.component('font-awesome-layers', FontAwesomeLayers)
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
    Vue.config.productionTip = false

    Vue.use(GetTextPlugin, {
      availableLanguages: {
        en: 'English',
        fi: 'Suomi',
        sv: 'Svenska'
      },
      defaultLanguage: 'fi',
      translations: translations,
      silent: true
    })

    new Vue({
      router,
      store,
      render: h => h(AppSapa)
    }).$mount('#app')
  })
  .catch(error => {
    console.log('Could not load app!', error)
  })
